import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import { api } from "services/api";
import { countriesApi } from "services/countriesApi";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    [api.reducerPath]: api.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
