export const AUTH_TOKEN = "auth_token";
export const AUTH_ROLE = "auth_role";
export const AUTH_STATE = "auth_state";

export const RoomStatus = {
  KEEPING: "KEEPING",
  CLEANING: "CLEANING",
  AVAILABLE: "AVAILABLE",
  NEEDS_CLEANING: "NEEDS CLEANING",
  NEEDS_MAINTAINANCE: "NEEDS MAINTAINANCE",
  MAINTAINANCE_IN_PROGRESS: "MAINTAINANCE IN PROGRESS",
};

export const BookingStatus = {
  CheckedIn: "CHECKIN",
  CheckedOut: "CHECKOUT",
};

export const BookingStatusTitles = {
  CHECKIN: "Checked in",
  CHECKOUT: "Checked out",
};
