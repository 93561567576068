import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { notification } from "antd";
import { API_BASE_URL } from "configs/AppConfig";
import { CUSTOM_API_ERROR_MESSAGES } from "constants/ApiConstant";
import { signOutSuccess } from "store/slices/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set(
        "accept-language",
        // i18n.language
        "ru"
      );
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(signOutSuccess());
    // try to get a new token
    //   const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    //   if (refreshResult.data) {
    // store the new token
    // api.dispatch()
    // retry the initial query
    // result = await baseQuery(args, api, extraOptions)
    //   } else {
    // api.dispatch(loggedOut())
    //   }
  }

  const error = result.error;

  if (error && error?.data) {
    const apiMessage = error?.data?.message;

    let notificationMessage = null;

    if (Array.isArray(apiMessage)) {
      notificationMessage = apiMessage.reduce((prv, curr) => {
        return prv + "\n" + curr;
      }, "");
    } else {
      notificationMessage =
        CUSTOM_API_ERROR_MESSAGES[apiMessage] ??
        (apiMessage ? apiMessage : "Something went wrong!");
    }

    notification.error({
      message: notificationMessage,
    });
  }

  return result;
};

export const TagTypes = {
  HOTELS_LIST: "HOTELS_LIST",
  ROOMS_LIST: "ROOMS_LIST",
  COMFORTABLES_LIST: "COMFORTABLES_LIST",
  GET_BOOKINGS: "GET_BOOKINGS",
  ROOM_LIST_WITH_BOOKINGS: "ROOM_LIST_WITH_BOOKINGS",
  RESERVATIONS_LIST: "RESERVATIONS_LIST",
  RECEPTION_ADMIN_LIST: "RECEPTION_ADMIN_LIST",
  TARIFF_PLANS_LIST: "TARIFF_PLANS_LIST",
  PAYMENT_RECORDS_LIST: "PAYMENT_RECORDS_LIST",
  AGENTS_LIST: "AGENTS_LIST",
  HOTEL_ME_DATA: "HOTEL_ME_DATA",
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDashboardAnalyticsData: builder.query({
      query: () => "/dashboard-analytics",
    }),
  }),
  tagTypes: Object.values(TagTypes),
});

export const { useGetDashboardAnalyticsDataQuery } = api;
