import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";

const ProtectedRoute = ({ canActivate }) => {
  const location = useLocation();

  if (!canActivate) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
