import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import { APP_PREFIX_PATH, AUTHENTICATED_ENTRY } from "configs/AppConfig";
import {
  adminRoutes,
  protectedRoutes,
  publicRoutes,
} from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import { useSelector } from "react-redux";

const Routes = () => {
  const token = useSelector((state) => state.auth.token);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  return (
    <RouterRoutes>
      {!isAdmin && (
        <Route path="/" element={<ProtectedRoute canActivate={token} />}>
          <Route
            path="/"
            element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
          />
          {protectedRoutes.map((route, index) => {
            return (
              <Route
                key={route.key + index}
                path={route.path}
                element={
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                }
              />
            );
          })}
          {!isAdmin && (
            <Route
              path="*"
              element={<Navigate to={APP_PREFIX_PATH} replace />}
            />
          )}
        </Route>
      )}

      {isAdmin && (
        <Route
          path="/"
          element={<ProtectedRoute canActivate={token && isAdmin} />}
        >
          {adminRoutes.map((route, index) => {
            return (
              <Route
                key={route.key + index}
                path={route.path}
                element={
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                }
              />
            );
          })}
        </Route>
      )}

      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
