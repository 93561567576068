import React, { lazy } from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "Dashboard",
    path: APP_PREFIX_PATH,
    component: lazy(() => import("views/app-views/dashboard/Dashboard")),
  },
  {
    key: "Edit Hotel",
    path: `${APP_PREFIX_PATH}/hotel/edit`,
    component: lazy(() => import("views/app-views/hotel/edit-hotel")),
  },
  {
    key: "Facilities",
    path: `${APP_PREFIX_PATH}/facilities`,
    component: lazy(() => import("views/app-views/facilities/facilities")),
  },
  {
    key: "Rooms",
    path: `${APP_PREFIX_PATH}/rooms`,
    component: lazy(() => import("views/app-views/rooms/rooms-list")),
  },
  {
    key: "Add room",
    path: APP_PREFIX_PATH + "/add-room",
    component: lazy(() => import("views/app-views/rooms/create-room")),
  },
  {
    key: "Edit Room",
    path: APP_PREFIX_PATH + "/edit-room/:id",
    component: lazy(() => import("views/app-views/rooms/edit-room")),
  },
  {
    key: "Reservations",
    path: APP_PREFIX_PATH + "/reservations",
    component: lazy(() =>
      import("views/app-views/reservations/reservations-list")
    ),
  },
  {
    key: "House keeping",
    path: `${APP_PREFIX_PATH}/house-keeping`,
    component: lazy(() =>
      import("views/app-views/house-keeping/house-keeping")
    ),
  },
  {
    key: "Guest registration",
    path: `${APP_PREFIX_PATH}/guest-registration`,
    component: lazy(() =>
      import("views/app-views/guest-registration/guest-registraion")
    ),
  },
  {
    key: "Check in & Checkout",
    path: `${APP_PREFIX_PATH}/checkin-checkout`,
    component: lazy(() =>
      import("views/app-views/checkin-checkout/CheckinCheckout")
    ),
  },
  {
    key: "Reception",
    path: `${APP_PREFIX_PATH}/reception`,
    component: lazy(() =>
      import("views/app-views/reception-control/reception-control")
    ),
  },
  {
    key: "Accounting",
    path: `${APP_PREFIX_PATH}/accounting`,
    component: lazy(() => import("views/app-views/accounting/accounting")),
  },
  {
    key: "Accounting",
    path: `${APP_PREFIX_PATH}/departure-list`,
    component: lazy(() =>
      import("views/app-views/departure-list/departure-list")
    ),
  },
];

export const adminRoutes = [
  {
    key: "Dashboard",
    path: APP_PREFIX_PATH,
    component: lazy(() => import("views/app-views/dashboard/Dashboard")),
  },
  {
    key: "Hotels",
    path: `${APP_PREFIX_PATH}/hotel/list`,
    component: lazy(() => import("views/app-views/hotel/hotels-list")),
  },
  {
    key: "Create Hotel",
    path: `${APP_PREFIX_PATH}/hotel/create`,
    component: lazy(() => import("views/app-views/hotel/create-hotel")),
  },
  {
    key: "Edit Hotel",
    path: `${APP_PREFIX_PATH}/hotel/edit`,
    component: lazy(() => import("views/app-views/hotel/edit-hotel")),
  },
];
