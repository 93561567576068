import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// https://restcountries.com/v3.1/all?fields=name

export const countriesApi = createApi({
  reducerPath: "countryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://restcountries.com/v3.1",
  }),
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => "/all?fields=name",
      transformResponse: (response) =>
        response?.map((country) => ({
          name: country.name.common,
        })),
      keepUnusedDataFor: 60 * 60,
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
