import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";

export const APP_NAME = "Innside";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const UPLOAD_ENDPOINT_SINGLE = `${API_BASE_URL}file-upload/image`;

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}`;
export const UNAUTHENTICATED_ENTRY = "/login";

export const CHANNEL_MANAGER_TELEGRAM_BOT = `https://t.me/my_innside_hotel_sardor_bot`;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};
