export const API_BASE_URL = "https://your-api-url.com/";

export const CUSTOM_API_ERRORS = {
  INTERVAL_OVERLAP: "INTERVAL_OVERLAP",
  PAST_DATE: "PAST_DATE",
};

export const CUSTOM_API_ERROR_MESSAGES = {
  [CUSTOM_API_ERRORS.PAST_DATE]: "Can't select past dates!",
  [CUSTOM_API_ERRORS.INTERVAL_OVERLAP]: "Overlapping with other bookings!",
};

export const CURRENCY_CODES = {
  USD: "USD",
  EUR: "EUR",
  RUB: "RUB",
  GBP: "GBP",
};

export const CurrencyMockData = [
  {
    id: 69,
    Code: "840",
    Ccy: "USD",
    CcyNm_RU: "\u0414\u043e\u043b\u043b\u0430\u0440 \u0421\u0428\u0410",
    CcyNm_UZ: "AQSH dollari",
    CcyNm_UZC: "\u0410\u049a\u0428 \u0434\u043e\u043b\u043b\u0430\u0440\u0438",
    CcyNm_EN: "US Dollar",
    Nominal: "1",
    Rate: "12304.98",
    Diff: "15.95",
    Date: "24.11.2023",
  },
  {
    id: 21,
    Code: "978",
    Ccy: "EUR",
    CcyNm_RU: "\u0415\u0432\u0440\u043e",
    CcyNm_UZ: "EVRO",
    CcyNm_UZC: "E\u0412\u0420\u041e",
    CcyNm_EN: "Euro",
    Nominal: "1",
    Rate: "13440.73",
    Diff: "43.23",
    Date: "24.11.2023",
  },
  {
    id: 57,
    Code: "643",
    Ccy: "RUB",
    CcyNm_RU:
      "\u0420\u043e\u0441\u0441\u0438\u0439\u0441\u043a\u0438\u0439 \u0440\u0443\u0431\u043b\u044c",
    CcyNm_UZ: "Rossiya rubli",
    CcyNm_UZC:
      "\u0420\u043e\u0441\u0441\u0438\u044f \u0440\u0443\u0431\u043b\u0438",
    CcyNm_EN: "Russian Ruble",
    Nominal: "1",
    Rate: "139.59",
    Diff: "0.32",
    Date: "24.11.2023",
  },
];
